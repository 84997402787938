#search-modal {
    background: #00000080;
    position: fixed;
    inset: 0;
    z-index: 1;
    align-items: start;
    justify-content: center;
    transition-duration: 100ms;
    display: none;
    z-index: 9999;

    &.show {
        display: flex;
    }

    .search-modal-wrapper {
        background: #fff;
        padding: 20px 20px 60px;
        transition-duration: 200ms;
        max-width: 1370px;
        width: 100%;

        .close {
            transition-duration: 200ms;
            font-size: 10px;
            font-weight: 500;
            color: #2B5F51;
            max-width: min-content;
            margin: 0 0 0 auto;

            &>span {
                margin: 10px 0 0 0;
                display: block;
            }
        }

        @media (min-width: $desktop) {
            margin: 110px 35px 0;
            padding: 40px 60px 100px;
            border-radius: 15px;
        }
    }

    #search-form {
        margin: 48px auto 0;
        max-width: 1046px;
        width: 100%;

        .search-input-bar {
            & .button {
                padding: 21px 10px;
                font-size: 14px;
                font-weight: 600;

                @media (min-width: $tablet) {
                    width: 140px;
                }

                &::after {
                    content: "Search";
                    width: auto;
                    height: auto;
                    background-image: none;
                }
            }
        }
    }
}