.slider-wrapper {
    max-width: 1025px;
    margin: auto;
    padding-left: 80px;
    padding-right: 80px;

    .slick-slide {
        margin: 0 30px;

        @media (max-width: 375px) {
            margin: 0 20px;
        }
    }

    .slick-arrow {
        
        &.slick-prev {
            background-image: url(../../../static/img/arrowprev.png);
            background-repeat: no-repeat;
            top: 30%;
            transform: translateY(-50%);
            left: 10px;
            z-index: 20;
            height: 64px;
            width: 64px;
        }
        
        &.slick-next {
            background-image: url(../../../static/img/arrow-next.png);
            background-repeat: no-repeat;
            top: 30%;
            transform: translateY(-50%);
            right: 10px;
            z-index: 20;
            height: 64px;
            width: 64px;
        }

    }

    .slick-prev:before {
        content: '';
      }
      
    .slick-next:before {
    content:'';
    }

    .video-container + p {
        color: $green;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.75rem;
        text-align: center;
        margin-top: 1.25rem;
    }
}