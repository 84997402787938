.content_block2 {
    position: relative;

    .heading-49 {
        margin-bottom: 12px;
    }

    .image-wrapper {
        width: 100%;
		height: 0;
		padding-top: 56.1%;
		position: relative;
		border-radius: 15px;
		overflow: hidden;
		margin-bottom: 24px;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center top;
        }
    }

    .block-link {
        margin-top: 28px;
        a {
            @extend .block-link;
        }
    }

    @media (min-width: $tablet) {
        .container {
            display: flex;
            align-items: center;
            gap: 48px;
        }
        .image-wrapper {
            padding-top: unset;
            height: 100%;
            width: 30%;
            flex-shrink: 0;
            flex-grow: 1;
            margin-bottom: 0;

            img {
                position: static;
            }
        }
        .content {
            width: calc(70% - 48px);
            padding: 16px 0;
        }
    }
}