footer {

    .footer-back-to-top {
        display: block;
        position: fixed;
        bottom: 24px;
        right: 0;
        height: 47px;
        width: 102px;
        margin: 24px 0 24px auto;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        padding: 10px 20px;
        transform: translateX(100%);
        transition: .2s ease;

        &::after {
            content: '';
            display: inline-block;
            height: 13px;
            width: 13px;
            background-image: url(../../../static/img/footer/back-to-top-arrow.svg);
            background-size: 13px;
            margin-left: 10px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .bg-offwhite {
        padding: 60px 20px;
    }
    .footer-content {
        max-width: 1175px;
        display: flex;
        justify-content: space-between;

        @media (max-width: $tablet) {
            flex-flow: column;
        }

        .footer-logo {
            flex-basis: 38%;

            img {
                max-width: 340px;
                margin-right: 30px;

                @media (max-width: $tablet) {
                    max-width: 222px;
                }
            }
        }

        .footer-info-section {
            flex-grow: 1;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            @media (max-width: $tablet) {
                flex-flow: column;
            }


            ul.grid {
                gap: 10px;
            }
            .footer-info,
            .footer-social {
                @media (max-width: $tablet) {
                    margin-top: 48px;
                }
            }

            .footer-info .grid {
                margin-right: 30px;
            }

            .footer-social {
                @media (max-width: $tablet) {
                    display: flex;
                    flex-flow: row wrap;
                }

                .accessability-icon {
                    @media (max-width: $tablet) {
                        flex-basis: 100%;
                        margin-top: 24px;
                    }
                }
            }

            .footer-menu-link {
                padding-bottom: 10px;
            }

            .footer-h3 {
                font-size: 1.3rem;
                margin-top: -3px;

                @media (max-width: $tablet) {
                    font-size: 1rem;
                    color: $black;
                }
            }
            
            .social-list {
                margin: 16px auto 24px auto;
                
                @media (max-width: $tablet) {
                    margin: -4px 30px 0 30px;
                }
            }

            .footer-tagline {
                margin-top: 48px;
                flex-basis: 100%;
                
                @media (min-width: $tablet) {
                    margin-top: 60px;
                }
            }
        }
    }

}