.page-speaker {
    .block-speaker {
        padding: 48px 0;
        .container {

            display: flex;
            flex-direction: column;
            gap: 24px;
            margin: 0 auto;

            .copy {
                h2 {
                    margin-top: 12px;
                    font-weight: 300;
                }
                .rich-text {
                    margin-top: 24px;
                    * {
                        color: $black;
                        font-weight: 18px;
                    }
                }
                
                h2, h1 {
                    text-align: center;
                }
            }

            @media (min-width: $tablet) {
                flex-direction: row;
                gap: 48px;
    
                .image {
                    width: 31%;
                    min-width: 200px;
                }
                .copy {
                    width: calc(69% - 48px);
                    max-width: 515px;
                    
                    h2 {
                        margin-top: 12px;
                        font-weight: 300;
                    }

                    h2, h1 {
                        text-align: left;
                    }
                    .rich-text {
                        margin-top: 24px;
                        * {
                            color: $black;
                            font-weight: 18px;
                        }
                    }
                }
            }
        }
    }

   
}