body {
  font-family: $poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body:has(dialog[open]) {
  main {
    overflow: hidden;

  }
}


html {
  overflow-x: hidden;
}

.h-1\/2 {
  height: 50% !important;
}

.video-background {
  video,
  iframe {
    position: absolute;
    inset: 0 0 0 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.text-light {
  .rich-text,
  .flexible-table-block {
    color: #fff;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    th,
    td {
      color: #fff;
    }
  }
}

.not-found {
  min-height: 35vw;
  max-height: 640px;
  margin-bottom: 60px;
  padding: 10vw 0;
  border-bottom: 6px solid $teal;
  @media (max-width: 767px) {
    height: 369px;
  }
 }

.block-one_column,
.block-three_columns {
  &.text-light,
  .text-light {
    .is-grid > div {
      a {
        color: #fff;
      }
    }
  }
}

.video-container {
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* 16:9 */
  height: 0;


  iframe,
  video {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.video-container {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .video_modal_block & {
    max-width: 100%;

    .modallauncher {
      text-align: left;
    }
  }
}

.footnote-text {
  font-size: 0.875em;
  line-height: 1.25rem;
}

.primary-menu > li .sub-menu .text-darkgreen {
  --tw-text-opacity: 1;
  color: rgb(43 95 81 / var(--tw-text-opacity));
  display: block;
  text-wrap: wrap;
}

.table-wrapper {
  overflow-x: auto;
}

body.home,
.template-404,
body.contact-us,
body.sitemap {
  .breadcrumb-wrapper {
    display: none;
  }
}

.template-404 {
  main {
    padding-bottom: 100px;
  }

  .block-hero + .rich-text {
    padding-top: 60px;
    @media (min-width: $desktop) {
      min-height: 157px;
    }
  }

  .suggested-links .container {
    padding-top: 64px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    a {
      text-decoration: underline;

      &:hover, &:focus {
        text-decoration: none;
      }
    }

  }
}

.spacer-block {
  height: 12px;
  @media (min-width: $tablet) {
    height: 24px;
  }
}

.audio-description-url {
  width: auto;
}

.header-bg {
  background: rgba($black, .5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  display: none;

  &.open {
      display: block;
  }
}