.rich-text {
  @extend .font-18;

  $colors-list: (
    'pink': $pink,
    'pinkdark' : $pinkdark,
    'mint': $mint,
    'green': $green,
    'teal': $teal,
    'teal2': $teal2,
    'gray': $gray,
    'darkgreen': $darkgreen,
    'seafoam': $seafoam,
    'seafoam-light': $seafoamlight,
    'red': $red,
    'dred': $dred,
    'black': $black,
    'offwhite': $offwhite,
    'lightgray2': $lightgray2,
    'lightgray3': $lightgray3,
    'lightgray4': $lightgray4,
);

@each $key, $val in $colors-list {
  .bg-#{$key} {
    &.inner {
      border-radius: 0 0 15px 15px;
      padding: 46px 20px;
      @media (min-width: $tablet) {
        padding: 46px 36px;
        border-radius: 15px;
      }
    }
  }
}



  *+h2,
  *+h3,
  *+h4,
  *+ul,
  *+ol,
  *:not(hr)+p {
    margin-top: 15px;
  }

  hr {
    width: 100%;
    border: 2px solid $mint;
  }

  a {
    color: $pinkdark;

    &:hover,
    &:focus {
    }
    &:has(u) {
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }


  b,
  strong {
    font-weight: 700;
  }

  h2 {
    @extend .heading-38;
  }

  h3 {
    @extend .heading-28
  }

  h4 {
    font-size: 2rem;
  }

  h5 {
    font-size: 1.5rem;
  }

  h6 {
    font-size: 1.25rem;
  }

  ul {
    padding-left: .4rem;

    li {
      position: relative;
      padding-left: 1em;

      &::before {
        content: '\2022';
        position: absolute;
        display: inline-block;
        width: 8px;
        left: 0;
        top: .1em;
      }
    }
  }

  ol {
    list-style-type: decimal;
    padding-left: 1.75rem;
  }

  @media (max-width: 500px) {
    a {
      overflow-wrap: break-word
    }
  }
}

main > .rich-text {

  @extend .block-padding;

  .rich-text-container{
    @extend .container;
  }
}