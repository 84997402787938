.return-to-top-block {
    display: flex;
    width: 100%;
    flex-flow: column;

    .top-bar {
        display: flex;
        align-items: center;

        & br {
            @media (min-width: 782px) {
                display: none;
            }
        }

        &>div {
            height: 60px;
            width: 60px;
            flex: 0 0 60px;
            position: relative;
        }

        & h2 {
            flex: 1 1 100%;
            align-content: center;
            font-weight: bold;
            color: #fff;
            padding-left: 20px;

        }

        & button {
            flex: 0 0 fit-content;
        }
    }

    .top-bar-image {
        top: -11px;
        position: absolute;
        left: -11px;
        min-width: 80px;
    }

    .rich-text-content {
        ul {
            list-style-type: none;
            padding: 0;

            li {
                position: relative;
                padding-left: 20px;
                margin-bottom: 25px;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0.7rem;
                    transform: translateY(-50%);
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: $teal;
                }
            }
        }
    }
} 