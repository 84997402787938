.video-modal-block-wrapper {
    @media (max-width: 1024px) {
        margin-bottom: 40px;
    }
}

.wrapperModalLaucher {
    text-align: center;
}
.video_modal_block {

    .video-grid {
        grid-gap: 24px 8px;
    }
    
    .videoModal {
        position: fixed;
        top: 0;
        left: 0; 
        z-index: 99999;
        width: 100%;
        height: 100%;
        background-color: $white;
        overflow: auto;
        margin: 0;
        min-height: 100vh;
        max-width: unset;
    }

    #active-video {
        display: none;
        margin-bottom: 48px;
        &.active {
            display: block;
        }

        .itemVideo::after {
            display: none;
        }
    }

    .videoModal.active {
        display: block;
    }

    .closeModal {
        position: absolute;
        top: 40px;
        right: 60px;
    }
    
    h2 {
        margin-bottom: 24px;
    }

    .font-18 {
        margin-top: 16px
    }

    .itemVideo.videoFull {

        .font-18 {
            margin: 0 0 24px;
            @extend .font-28;
        }
    }


    .itemVideo {
        position: relative;
        button {
            display: block;
            width: 100%;
            text-align: left;
        }
    }

    .itemVideo::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
    }

    .videoFull::after {
        display: none;
    }

    .container {
        padding: 120px 20px !important;
    }

    h3 {
        padding-bottom: 24px;
    }

    .video-section {
        + .video-section {
            margin-top: 64px;
        }
    }
    @media screen and (max-width: 767px) {
        .closeModal {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .container {
            padding: 100px 20px;
        }

        .videoModal__content>.header {
            flex-direction: column-reverse;
            align-items: flex-start;
        }

        .videoModal__content>.header button {
            align-self: flex-end;
            margin-bottom: 20px;
        }

        .itemVideo.videoFull {
            display: flex;
            flex-direction: column-reverse;
            grid-column: span 1 / span 1;
            order: -9999;
            margin-bottom: 20px;
        }
    }
}