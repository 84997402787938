.linked-cards {
	.cards {
		display: flex;
		flex-direction: column;
		gap: 50px;
	}

	span {
		display: block;
		margin-top: 28px;
	}

	.card-image {
		width: 100%;
		height: 0;
		padding-top: 36.5%;
		position: relative;
		border-radius: 15px;
		overflow: hidden;
		margin-bottom: 15px;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center top;
		}
	}

	.heading-28 {
        @include rem(font-size, 28);
        font-weight: 300;
        line-height: normal;
    }

	.link {
		margin-top: 24px;
	}

	@media (min-width: $mobile) {
		.card-image {
			padding-top: 50.7%;
		}
	}
	

	@media (min-width: $tablet) {
		.cards {
			flex-direction: row;
			gap: 50px;
		}

		.card {
			display: flex;
			flex-direction: column;
			width: calc(33.3333% -  16.6667px);
		}	
		.card-inner {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex-grow: 1;
		}
	}
}