.webcast-card.webcast-banner {
    color: #000;


    .content {
        background: #F0F3F3;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 562px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (min-width: $tablet) {
            min-height: unset;
        } 
    }


    .block-link {
        @extend .btn;
        @extend .main-button;
        text-decoration: none !important;
        background: $pinkdark;

        &::after {
            width: 12px;
            height: 21px;
            background: url('/static/img/caret.svg') center/contain no-repeat !important;
            position: unset !important;
            transform: none;
        }
        &:hover, &:focus {
            text-decoration: underline !important;
        }
    }
}

.webcast-card.webcast-image-header {
    padding: 0;


    
    .inner.bg-white {
        background-color: transparent;
        margin: 30px auto;
    }

    &--header2 {
        padding: 0;
        @media (max-width: 1023px) {
            font-size: 32px;
            font-weight: 300;
            padding: 20px 30px;
        }
    }
}

.webcast-card {
    .content {
        border-radius: 25px;
        overflow: hidden;
    }

    &-banner-copy {
        position: relative;
        display: inline-block;
        max-width: 475px;
        margin: 43px 100px;
        border-radius: 25px;

        @media (max-width: $tablet) {
            margin: 2rem;
        }
    }

    &--header2 {
        font-size: 49px;
        font-weight: 300;
        color: $white;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;

        &.bg-green {
            padding: 20px 30px;
        }
    }

    &--header2+p {
        margin: 15px 0 30px 0;
    }

    &--header2+.webcast-card--image img {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    &--image {
        img {
            width: 100%;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
        }
    }

    .rounded-corners img {
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }

    .content-in-row {
        .column-container {
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            justify-content: space-between;
            gap: 36px;
        }

        .column-container > div {
            flex-basis: 30%;

            @media (max-width: $tablet) {
                flex-basis: 100%;
            }
        }
    }

}