body#privacy-policy {
    .block-hero {
        &:has(.hero_block2) {
            +div {
                .column-container {
                    max-width: unset;
                }

                .inner {
                    max-width: unset;
                }
            }

            +section {
                .column-container {
                    max-width: unset;
                }

                .inner {
                    max-width: unset;
                }
            }
        }
    }

    #onlineactivitydata,
    #correspodancetype,
    #digitalinfotype,
    #Demograph {
        margin-top: -.6em;
    }
}