#meet-our-people {
    .webcast-card--content {
        .block-one_column + .block-one_column {
            .container {
                background: $seafoamlight;
            }
            .rich-text {
                padding-top: 0;
                flex-basis: 100%;
            }
            .rich-text .inner {
                background: none !important;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}