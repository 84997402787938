.page,
.search-form input[type="submit"],
.text-pink,
.searchlinks a {
  color: $pinkdark;
}

.page,
.search-form input[type="submit"] {
  background-color: transparent;
  border: 1px solid $pinkdark;
}

.search-form input[type="submit"] {
  color: white;
  background-color: $pinkdark;
  border: none;
  padding: 0.8em 1.5em;
}

.search-form input[type="submit"]:hover {
  background-color: $pinkdark;
  color: white;
}

.search-form input[type="text"] {
  border-bottom: 1px solid $black;

  &:focus {
    outline: none;
  }
}

#search-page {
  padding-bottom: 100px;

  .flex-container {
    width: 100%;
    @media (min-width: $desktop) {
      display: flex;
      justify-content: space-between;
      gap: 66px;

      .results {
        width: 64%;
        max-width: 750px;
        flex-shrink: 0;
      }

      .resources-list {
        width: calc(36% - 66px);
        flex-grow: 0;
      }
    }
  }
  
  .search-hero {
    background: $black;
    height: 226px;
    background: url('/static/img/search-hero-mobile.png');
    background-position: top left;
    background-size: 800px;
    overflow: hidden;
    position: relative;
    display: flex;

    &>div {
      margin: auto;

    }

    .heading-2 {
      margin: auto;
    }

    img {
      position: absolute;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    @media (min-width: $mobile) {
      height: 400px;
      padding-bottom: 157px;
      background: url('/static/img/search-hero-desktop.png');
      background-size: cover;

      .heading-2 {
        font-size: 3.2222rem;
      }
    }
  }

  .hero_block2 {
    &::after {
      content: unset;
    }
  }

  .result {
    a {
      text-decoration: underline;
      &:hover, &:focus {
        text-decoration: none;
      }
    }

    + .result {
      margin-top: 48px;
    }
  }

  .search-input-wrapper {
    position: relative;

    #clear-search {
      display: none;
      position: absolute;
      right: 10px;
      top: 16px;
    };

    input {
      padding-right: 30px;
    }
  }



  .search-clip {
    position: relative;
    margin-bottom: 60px;
    z-index: 0;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 190vw;
      height: 157px;
      transform: skew(55deg);
      transform-origin: right center;
      background: #fff;
      mask: radial-gradient(144px 157px ellipse at 100% 0, transparent 99.9%, #fff 100%);
      z-index: 1;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      height: 157px;
      width: 100%;
      background: rgba(217, 217, 217, .5);
    }
    @media (max-width: $mobile) {
    &::before,
    &::after {
      content: unset;
    }
  }
    @media (min-width: $mobile) {
      margin-bottom: -97px;
    }
  }


  .search-content {
    position: relative;
    display: flex;
    flex-flow: column;

    #search-form {
      margin: 0 160px 0 0;
      max-width: 1016px;
      width: 100%;
      flex: 1 1 100%;
      margin-bottom: 64px;
    }

    .resources-list {
      display: flex;
      flex-flow: column;

      h3 {
        font-size: 1.75rem;
        color: $darkgreen;
        font-weight: 300;
        margin-bottom: 24px;

      }
      li {
        margin-bottom: 12px;
      }
    }

    @media(min-width: $tablet) {
      flex-flow: row;
      flex-wrap: wrap;
      .results {
        width: auto;
        flex: 1 1 auto;
      }
      .resources {
        max-width: 350px;
        flex: 1 1 350px;
      }
    }
  }
}