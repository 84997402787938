body#annual-reports {
    .block-hero {
        @media (max-width: $desktop) {
            padding-bottom: 60px;
        }
    }
    p {
        position: relative;

        >sup:first-of-type:first-child {
            position: absolute;
            top: .7rem;
            transform: translateX(-100%);
        }
    }

    #footnote {
        p {
            position: relative;
            sup {
                position: absolute;
                top: .8em;
            }
        }
    }

    .webcast-container.container {
        padding-left: 0;
        padding-right: 0;
    }

    .content-block > .rich-text .rich-text-container {
        padding: 0 20px;
    }
}