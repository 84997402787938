body#td-awareness {
  .column-container {
    > .video-container {
      @media (max-width: 768px) {
        padding-top: 100%;
      }
      
      &::after {
        background: url('/static/img/play-black.svg') center/contain no-repeat;
      }
    }
  }

  #pre-td-video {
      p {
        @extend .symbol-text;
      }
  }

  // Hiding resources temporarily till new pdfs arrive in May
  .webcast-image-header:has(#hidden-resources) {
    display: none;
  }

  .block-three_columns {
    .column-container {
      img {
        margin: 0 auto;
      }
    }
  }

  #neurocrine-biosciences {
    .column-container {
        img {
          border-radius: 15px;
        }
    }
  }
}