.exit-modal {

    &::backdrop {
        pointer-events: none;
        background: transparent;
    }

    .modal-overlay {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
    }

    .modal-overlay {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
    }

    .modal-content {
        padding: 40px 20px 100px;
        z-index: 999999;
        left: 50%;
        top: 50%;
        position: fixed;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    .closeModal {
        position: absolute;
        top: 30px;
        right: 20px;
    }

    h2 {
        font-size: 36px;
        font-weight: 600;
        line-height: normal;
        margin: 48px 0;
    }

    #exit-continue {
        &:hover {
            text-decoration: none;
        }
    }

    .link-block.primary {
        padding-left: 50px;
        &::after {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
        }
    }

    .actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    button.close {
        font-size: 21px;
        text-decoration: none;

        &::after {
            display: none !important;
        }

        &::before {
            width: 12px;
            height: 21px;
            background: url('/static/img/caret.svg') center/contain no-repeat;
            transform: rotate(180deg);
        }
    }



    @media (min-width: $tablet) {

        .modal-content {
            padding: 40px 60px 100px;
            border-radius: 15px;
            max-width: 1370px;
            width: calc(100% - 40px);
        }

        h2 {
            font-size: 48px;
        }

        .closeModal {
            position: absolute;
            top: 60px;
            right: 40px;
        }

        .actions {
            flex-direction: row;
            justify-content: center;
            gap: 80px;
        }

        .link-block.primary {
            padding-left: 50px;
            margin-left: -25px;
            &::after {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
}

