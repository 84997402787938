
.search-input-bar {
    /* Pulled this block out of the search filter CSS to make it reusable */
    display: flex;
    height: 50px;
  
    .search-input-wrapper {
        flex: 1 0 0;
        display: flex;
        align-items: center;
        height: 100%;
  
        input {
            height: 100%;
            width: 100%;
            padding: 0 20px;
            font-size: 16px;
            border: 1px solid #2B5F51;
            border-right: 0;
            border-radius: 12px !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
    .button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 16px;
        font-size: 16px;
        font-weight: 600px;
        color: #ffffff;
        background-color: #CF3B91;
        border: 1px solid #CF3B91;
        border-radius: 12px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
  
        @media (min-width: 768px) {
            padding: 10px 36px;
        }
  
        &:after {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            background-image: url("/static/img/magnifying-glass.svg");
            background-repeat: no-repeat;
  
            @media (min-width: 768px) {
                content: 'Search';
                width: auto;
                height: auto;
                background-image: none;
            }
        }
  
    }
  }

.news-filter-block {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
    }

    .filter-by-year {
        width: 100%;

        @media (min-width: 768px) {
            width: 33.33%;
        }
        @media (min-width: 1024px) {
            width: 33.33%;
        }

        .year-select-box {
            align-items: center;
            height: 50px;

            .select-wrap {
                width: 100%;
                height: 50px;
                padding: 0 16px 0 0;
                font-size: 16px;
                border: 1px solid #2B5F51;
                border-radius: 12px;
                background-color: transparent;
                color: #2B5F51;
            }

            select {
                width: 100%;
                height: 50px;
                padding: 0 0 0 20px;
                font-size: 16px;
                border: 0;
                border-radius: 12px;
                background-color: transparent;
                color: #2B5F51;
            }
        }
    }

    .filter-text-search {
        width: 100%;
        margin-top: 48px;

        @media (min-width: 768px) {
        width: 66.66%;
        margin-left: 24px;
        margin-top: 0;
        }
        @media (min-width: 1024px) {
        width: 66.66%;
        margin-left: 48px;
        }
        .text-input-submit-bar {       
            .text-input-wrapper {
                @media (min-width: 768px) {
                    max-width: 500px;
                }
            }
        }
    }
}