.icon-after {
    position: relative;
    &::after {
        content: "";
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-left: 12px;
        pointer-events: none;
    }
}

.icon-before {
    position: relative;
    &::before {
        content: "";
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
        pointer-events: none;
    }
}

.with-caret {
    @extend .icon-after;
    &::after {
        width: 12px;
        height: 21px;
        background: url('/static/img/caret.svg') center/contain no-repeat;
    }
}
.with-external-arrow {
    @extend .icon-after;
    &::after {
        width: 13px;
        height: 13px;
        background: url('/static/img/external-icon.svg') center/contain no-repeat;
    }
}

.block-link {
    font-weight: 500;
    color: $pinkdark;
    font-size: 18px;
    line-height: normal;

    &::after {
        position: absolute !important;
    }

    &.tertiary {
        text-decoration: underline;
        &:hover, &:focus {
            text-decoration: none;
        }
    }

    &.internal-link {
        &::after {
            transform: translateY(.3em);
        }

        &.primary {
            text-decoration: underline;
            @extend .with-caret;
        
            &:hover, &:focus {
                text-decoration: none;
            }
            @media (max-width: 600px) {
                max-width: calc(100% - 30px);
            }
        }
    
        &.secondary {
            text-decoration: none;
            @extend .with-caret;
            &:hover, &:focus {
                text-decoration: underline;
            }
            @media (max-width: 600px) {
                max-width: calc(100% - 30px);
            }
        }
    }
    &.external-link {
        &::after {
            transform: translateY(.5em);
        }

        &.primary, &.secondary {
            text-decoration: underline;
            @extend .with-external-arrow;
            &:hover, &:focus {
                text-decoration: none;
            }
            @media (max-width: 600px) {
                max-width: calc(100% - 30px);
            }
        }
    
        &.quaternary {
            text-decoration: underline;
            @extend .icon-before;
            padding-left: 32px;
            position: relative;
            text-align: left;

            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 24px;
                height: 24px;
                background: url('/static/img/download-pdf.svg') center/contain no-repeat;
            }
            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }
    &.download-link {
        &.primary {
            text-decoration: underline;
            @extend .icon-after;
            &::after {
                position: relative !important;
                width: 16px;
                height: 16px;
                background: url('/static/img/download-icon.svg') center/contain no-repeat;
            }

            &:hover, &:focus {
                text-decoration: none;
            }
            @media (max-width: 600px) {
                max-width: calc(100% - 30px);
            }
        }
    
        &.secondary {
            text-decoration: underline;
            @extend .icon-before;
            padding-left: 32px;
            position: relative;
            text-align: left;

            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 24px;
                height: 24px;
                background: url('/static/img/download-pdf.svg') center/contain no-repeat;
            }
            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }
}


.main-button {
    border-radius: 15px;
    background: $pinkdark;
    padding: 10px 17px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    color: $white;
    font-size: 21px;
    font-weight: 500;
    line-height: normal;
    @extend .with-caret;

    @media (min-width: $desktop) {
        padding: 10px 40px;
    }

    &::after {
        transition: .25s ease;
        filter: brightness(1000%) grayscale(100%) !important;
    }
}



.btn {
    display: inline-block;
    border: none;
    background: transparent;


    &.primary, &.secondary, &.tertiary {
        @extend .main-button;
    }

    &.secondary {
        &::after {
            width: 16px;
            height: 16px;
            background: url('/static/img/download-icon.svg') center/contain no-repeat;
        }
        &:hover, &:focus {
            &::after {
                transform: translateX(0);
            }
        }
    }

    &:hover, &:focus {
        text-decoration: underline;
        &::after {
            transform: translateX(10px);
        }
    }
}


.link-resource {
    a,  .block-link {
        all: unset !important;
        color: $gray !important;
        border: 1px solid $gray !important;
        padding: 12px 36px !important;
        border-radius: 15px !important;
        background: $seafoamlight !important;
        display: inline-block !important;
        cursor: pointer !important;

        &::after, &::before {
            display: none !important;
        }

        &:hover, &:focus {
            text-decoration: underline !important;
        }
    }
}