.interactive-map {
	border: 1px solid $lightgray;

	.point {
		&:hover {
			.location-blurb {
				display: block;

				@media (max-width: 767) {
					position: sticky;
					width: 94.8vw;
					max-width: none;
					margin-top: 70px;
				}
			}
		}
	}

	.location-blurb {
		position: absolute;
		top: 0;
		left: 0;
		width: 450px;
		max-width: 450px;
		background: $white;
		border: 1px solid $green;
		padding: 20px 25px 15px;
		z-index: 10;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
		display: none;

		.title {
			color: $green;
			font-weight: 600;
			font-size: 24px;
		}

		.location {
			color: $gray;
			margin-bottom: 15px;
		}

		p {
			font-size: 16px;
			margin-bottom: 15px;
		}

		a {
			color: $pinkdark;
			text-decoration: underline;
			font-size: 18px;
			font-weight: 500;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.point {
		width: clamp(10px, 3vw,30px);
		height: clamp(10px, 3vw, 30px);
		background: $pinkdark;
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: 0;
		cursor: pointer;

		&:after {
			content: "";
			width: clamp(18px, 4.5vw, 40px);
			height:clamp(18px, 4.5vw, 40px);
			border: 2px solid $pinkdark;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&:hover {
			background: $pink;

			&:after {
				border-color: $pink;
			}
		}

		$width: 1173;
		$height: 568.16;

		$point-size: calc(clamp(18px, 4.5vw, 40px)/2);

		@mixin map-pins-left($top, $left) {
			top: ($top / $height) * 100%;
			left: ($left / $width) * 100%;
		}
		@mixin map-pins-right($top, $right) {
			left: initial;
			top: ($top / $height) * 100%;
			right: ($right / $width) * 100%;
		}

		&.pp-1 {
			@include map-pins-left(137, 89.2);
			.location-blurb {
				transform: translate(-6%, calc(-100% + $point-size));
			}
		}
		&.pp-3 {
			@include map-pins-left(171, 240);
			.location-blurb {
				transform: translate(-4%, calc(-100% + calc($point-size/2)));
			}
		}
		&.pp-4 {
			@include map-pins-left(236, 245);
			.location-blurb {
				transform: translate($point-size, calc(-50%));
			}
		}
		&.pp-5 {
			@include map-pins-left(210, 280);
			.location-blurb {
				transform: translate($point-size, calc(-50%));
			}
		}
		&.pp-7 {
			@include map-pins-left(135, 516);
			.location-blurb {
				transform: translate(-40%, -75%);
			}
		}
		&.pp-8 {
			@include map-pins-left(178, 560);
			.location-blurb {
				transform: translate(-15%, calc(-60% + $point-size));
			}
		}
		&.pp-9 {
			@include map-pins-right(240, 90);
			.location-blurb {
				left: unset;
				right: -6vw;
				transform: translate($point-size, calc(-50%));
			}
		}
		&.pp-10 {
			@include map-pins-right(183, 57);
			.location-blurb {
				left: unset;
				right: -6vw;
				transform: translateY(-50%);
			}
		}

		
	}
	@media (max-width: 767px) {
		transform: translateZ(0);
		.point .location-blurb {
			left: 0 !important;
			right: 0 !important;
			max-width: 100% !important;
			width: 100% !important;
			top: 57%;
			transform: none !important;
			right: unset !important;
			position: fixed !important;			
		}
	}
}