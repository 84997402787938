.ccpa-form-wrapper {
  padding: 60px 0;

  @media (min-width: $tablet) {
    padding: 100px 0;
  }

.intro {
  margin-bottom: 24px;
}



  label,
  legend,
  input {
    display: inline-block;
    color: #6B6C6C;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
  }

  .top-fields {
    max-width: 682px;

    input,
    select {
      width: 100%;
      min-height: 59px;
      font-weight: 300;
      padding-left: 16px;
      padding-right: 16px;
    }

    select {
      appearance: none;
      padding-right: 36px;
    }

    .select-wrapper {
      position: relative;

      &::after {
        position: absolute;
        content: '';
        right: 16px;
        bottom: 24px;
        height: 10px;
        width: 20px;
        display: inline-block;
        background: url('/static/img/ccpa-arrow.svg') center/contain no-repeat;
        pointer-events: none;
        cursor: pointer;
      }
    }
  }

  .form-field {
    margin-top: 36px;
  }

  .selections {
    fieldset {
      margin-top: 48px;
    }

    legend {
      margin-bottom: 24px;
    }

    label {
      font-weight: 300;
    }
  }

  input,
  select {
    border: $border solid 1px;
    padding: 8px;
    cursor: pointer;
  }

  .choice-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;

    label {
      margin-bottom: 0;
    }

    +.choice-wrapper {
      margin-top: 32px;
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    appearance: none;
    width: 30px;
    height: 30px;

    &:checked {
      background: $darkgreen;
    }
  }

  input[type="radio"] {
    border-radius: 100%;
  }



  .formfield-required {
    color: #c02b0a;
    font-style: italic;
    font-weight: 400;
    display: inline-block;
    font-size: 13.008px;
    padding-inline-start: 0.125em;
  }

  .required-missing {

    &.form-field {
      label,
      p {
        color: $red;
      }
    }

    input[required] {
      border: $red solid 1px;
    }
  }

  .required-field-msg {
    color: $red;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin-top: 8px;
  }
  
  .submit-wrapper {
    display: inline-block;
    position: relative;
    margin-top: 48px;
    text-align: center;


    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 40px;
      display: inline-block;
      margin-left: 12px;
      width: 12px;
      height: 21px;
      filter: brightness(1000%) grayscale(100%) !important;
      background: url('/static/img/caret.svg') center/contain no-repeat;
      pointer-events: none;
    }

    @media (min-width: $tablet) {
      text-align: left; 
    }
  }

  #input-submit {
    border-radius: 15px;
    background: $pinkdark;
    padding: 10px 40px 10px 40px;
    padding-right: calc(40px + 16px + 12px);
    display: inline-block;
    color: $white;
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 0;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      background-color: $gray;
    }
  }
}