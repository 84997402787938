/**
*
* Secondary (Investors Pages) Navigation
*
*/

.menu-ir-placeholder {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    background-color: $white;
}

.ir-navigation {
    min-height: 84px;

    @media (max-width:$desktop) {
        min-height: 73px;
        z-index: 3;
        width: 100%;
        position: relative;
        margin-top: 0;
        background-color: $white;

        .secondary-nav .menu-item:not(:first-child) {
            display: none;
        }
        &.menu-open {
            .secondary-nav .menu-item:not(:first-child) {
                display: block;
            }

            #current-page-menu-item::before {
                transform: rotate(180deg);
            }
        }


    }
}

.secondary-nav {
    max-width: $max-container;
    margin: auto;
    flex-flow: row;
    justify-content: start;
    align-items: center;
    gap: 7%;
    padding: 0 20px;

    @media (max-width: $desktop-lg) {
        padding: 0 40px 0 20px;
        justify-content: space-between;
        gap: 0;
        overflow: auto;
    }

    @media (max-width: $desktop){
        align-items: unset;
        overflow-x: scroll;
        flex-flow: column;
        padding: 0;
        border-radius: 5px;
        margin: 15px 20px;
        font-size: 14px;
        background-color: $offwhite;
        position: absolute;
        width: calc(100% - 40px);

        &.showing {
            display: block;
            border-left: $gray solid 1px;
            border-right: $gray solid 1px;
            border-bottom: $gray solid 1px;
            padding-bottom: 10px;

            #current-page-menu-item{
                border-right: 0;
                border-left: 0;
            }
        }
    }

    .active{
        >a, >div {
            position: relative;
            color: $pinkdark;
            font-weight: 600 !important;
        }

        div:has(+.sub-menu):after {
            background: url(../../../static/img/header/caret-pink.svg) no-repeat 0 0 transparent !important;
            background-size: 19px 10px !important;

            @media (max-width: $desktop){
                background-size: 10px 5px !important;
            }
        }
    }

    .open {
        .sub-menu {
            display: block !important;
        }
        div:has(+.sub-menu):after {
            transform: rotate(180deg);
        }
    }

    .secondary-nav.showing {
        #current-page-menu-item {
            margin-bottom: 20px;
        }
    }

    #current-page-menu-item {
        display: none;

        @media (max-width:$desktop) {
            display: block;
            padding: 10px 0 10px 20px;
            border: $gray solid 1px;
            border-radius: 5px;
            margin-left: 0;

            &:before {
                content: "";
                background: url(../../../static/img/header/header-caret.svg) no-repeat 0 0 transparent;
                width: 19px;
                height: 10px;
                position: absolute;
                right: 25px;
                top: 15px;
                background-size: cover;
            }
        }
    }

    >.menu-item {
        width:min-content;

        @media (min-width: 1025px) {
            &.ml-5:nth-child(2) {
                margin-left: 0 !important;
            }
        }

        @media (max-width: $desktop) {
            width: auto;
            margin-right: 1.25rem;
        }

        a, div {
            display: block;
            cursor: pointer;
            text-align: left;
            padding: 15px 0;
            transition: all 0.2s ease-in-out;
            font-weight: 400;
            text-decoration: none !important;
            position: relative;

            @media (max-width:$desktop) {
                padding: 10px 0;
            }

            &:has(+.sub-menu):after {
                content: "";
                background: url(../../../static/img/header/header-caret.svg) no-repeat 0 0 transparent;
                width: 19px;
                height: 10px;
                position: absolute;
                right: -25px;
                top: 40%;
                // transform: translateY(calc(-50% - 2px));
                background-size: cover;

                @media (max-width: $desktop) {
                    width: 10px;
                    height: 5px;
                    right: auto;
                    top: 18px;
                    margin-left: 10px;
                }
            }
        }

        .sub-menu {
            display: none;
            background: $white;
            position: absolute;
            width: 100%;
            max-width: 280px;
            padding: 30px 10px 20px;
            margin-top: 6px;
            margin-left: -30px;
            border-top: $lightgray 1px solid;
            background: #fff;
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;
            z-index: 9;
            box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .0509803922), -4px 4px 4px 0 rgba(0, 0, 0, .050980);

            @media (max-width: $desktop) {
                background-color: $offwhite;
                position: relative;
                border: none;
                box-shadow: none;
                padding: 0;
                margin: 0;
            }
        }

        &:last-of-type .sub-menu{
            @media (min-width: $desktop) and (max-width: $desktop-xl) {
                right: 0;
            }
        }
    }
}