$max-container: 1215px; // includes padding below

.container {
    padding: 0 20px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: $max-container;
}

.container-margin {
    margin: auto 37px;

    @media (min-width: $tablet) {
        margin: auto 25px;
    }
}