.content_block3 {
    blockquote p {
        font-style: normal;
    }

    .serendipity-content {
        color: $green;
        font-weight: 600;
        font-size: 16px;
        b {
            color: $teal;
            .image-left & {
                color: $teal2;
                font-size: 1.25rem !important;
            }
        }
    }

    .copy {
        margin-top: 20px;
    }

    .rich-text + .quote-block {
        margin-top: 3rem;
        border-top: 4px solid $mint;
        padding-top: 3rem;
    }

    .btn {
        margin-top: 20px;
        font-size: 1.125rem;
        padding: 1rem 2.5rem;
    }
    
    @media (min-width: 1025px) {
        min-height: 520px;

        .copy {
            padding-left: 0 !important;
            margin-top: 05
        }

        .rich-text + .quote-block {
            margin-top: 5rem;
            padding-top: 5rem;
        }
        
        &.image-right {

            .right-0 {
                background-position: bottom right !important;
            }
            img {
                margin-left: auto;
                display: block;
            }
            .serendipity-content {
                font-size: 33px;
            }
        }

        &.media-less-than-half {
            .right-0 {
                width: 41.666667%;
            }
        }

        &.image-left {
            display: flex;
            align-items: center;

            .serendipity-content {
                font-size: 1.25rem;
            }
        
            .right-0 {
                right: initial;
                left: 0;
                overflow: hidden;
                background-position: bottom right !important;
                &.bg-video {
                    left: -1px;
                    bottom: -1px;
                }
            }
        
            .copy {
                margin-left: auto;
            }
        }
    }
}