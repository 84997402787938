.clinical-study-block {
    padding-bottom: 24px;
    color: $gray;

    @media (min-width: $desktop) {
        padding-bottom: 48px;
    }

    h2 {
        margin-bottom: 24px;
        font-weight: 300;
    }

    table *, .blurb * {
        @extend .font-16;
        font-weight: 500;
    }

    sup {
        top: 0;
    }
    .desktop-view {

        table {
            border-radius: 15px;
            overflow: hidden;
        }

        td, .details {
            font-weight: 500;
        }

        .trials-table {
            width: 100%;
            border-radius: 15px 15px 0 0px;
            overflow: hidden;
            text-align: center;
        }

        th {
            padding: 10px;
            height: 60px;
            width: 20%;
            border: 1px solid $white;
        }

        td {
            height: 80px;
            padding: 10px;
            width: 20%;
            border: 1px solid rgba(219, 237, 237, 0.40);

            .block-link {
                display: block;
            }
        }

        .expanded-content {
            padding: 15px 15px 15px 30px;
            text-align: left

        }
    }

    .mobile-view {

       
        .header {
            font-size: 16px;
        }

        .buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;
            margin-bottom: 24px;

            a {
                display: inline-block;
                width: auto;
                flex-grow: 0;
                text-decoration: none !important;
                @extend .main-button;
                font-size: 16px;
                position: unset !important;
                transform: none;

                &::after {
                    width: 13px;
                    height: 13px;
                    background: url('/static/img/external-icon.svg') center/contain no-repeat;
                    position: unset !important;
                    transform: none;
                }  

                &:hover, &:focus {
                    text-decoration: underline !important;
                }
            }
           
        }
        .blurb .inner {
            padding-top: 24px;
            display: grid;
            gap: 24px;
        }

        table {
            width: 100%;
        }
        
        th, td {
            padding: 10px;
            height: 50px;
        }

        .weight-700 {
            font-weight: 700;
        }
        
        h3 {
            font-weight: 500;
        }

        tr.split {
            td {
                width: 50%;
                height: 60px;
                &:first-child {
                    border-bottom: 1px solid $white;
                }
                &:last-child {
                    border-bottom: 1px solid $seafoamlight;
                    border-top: 1px solid $seafoamlight;

                }
            }
        }
    }

}