.custom-select {
    position: relative;

    &.active {
        ul {
            display: block;
        }
        .arrow {
            transform: translateY(-50%) rotate(0deg);
            transform-origin: center;
        }
    }

    button,
    ul {
        display: block;
        background: white;
        border: 0;
    }

    button {
        width: 100%;
        display: flex;
        white-space: nowrap;
        justify-content: space-between;
        align-items: center;
        position: relative;
        border: 1px solid $gray;
        padding: 12px 60px 12px 30px;
        border-radius: 15px;
        @extend  .font-20;

        @media (min-width: $desktop-lg) {
            font-size: 24px;
        }

        .arrow {
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
            z-index: 3;
        }
    }

    .title {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    ul {
        position: absolute;
        left: 10px;
        right: 10px;
        top: 100%;
        padding: 48px 10px 48px 30px;
        overflow-y: auto;
        display: none;
        z-index: 9;
        border-radius: 0px 0px 15px 15px;
        background: $white;
        box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.05), 4px 4px 4px 0px rgba(0, 0, 0, 0.05);

        li {
            cursor: pointer;
            transition: all .3s ease-in;
            position: relative;
            margin-bottom: 15px;
            @extend .font-20;

            &::before {
                content: "\2713";
                vertical-align: middle;
                font-weight: 900;
                position: absolute;
                right: .8rem;
                opacity: 0;
                transition: opacity .300s ease-out;
            }

            &:hover,
            &.current {
                background: #e6e6e6;
            }

            &.selected-option::before {
                opacity: 1;
            }
        }
    }
}