html {
    overflow-x: hidden;
}

body {
    font-family: $poppins;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $gray;
}


// General Serendipity font style
.serendipity-text {
        font-family: $serendipity;
        @include rem(font-size, 45);
        line-height: normal;
        letter-spacing: -0.45px;

    @media (min-width: $desktop) {
        @include rem(font-size, 84);
        letter-spacing: -0.84px;
    }
  }


.heading-1 {
    @include rem(font-size, 64);
    font-weight: 300;
    line-height: calc(60/64);
    @include rem(letter-spacing, -0.64);
    @media (min-width: $desktop) {
        @include rem(font-size, 140);
        font-weight: 300;
        @include rem(letter-spacing, -7);
    }
}

.heading-2 {
    @include rem(font-size, 42);
    font-weight: 300;
    line-height: calc(57.5/42);
    @include rem(letter-spacing, -0.42);

    @media (min-width: $desktop) {
        @include rem(font-size, 58);
        line-height: calc(77/58);
        letter-spacing: unset;
    }
}



.heading-49 {
    @include rem(font-size, 32);
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    @media (min-width: $desktop) {
        @include rem(font-size, 49);
        line-height: calc(52/49);
        font-weight: 300;
    }
}


.heading-38 {
    @include rem(font-size, 32);
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    @media (min-width: $desktop) {
        @include rem(font-size, 38);
        font-weight: 300;
    }
}


// taken from an instance where font size was bigger on mobile, not sure if global
.heading-28 {
    @include rem(font-size, 32);
    font-weight: 300;
    line-height: normal;

    @media (min-width: $desktop) {
        @include rem(font-size, 28);
        font-weight: 300;
        line-height: normal;
    }
}


.font-16 {
    @include rem(font-size, 16);
    font-weight: 300;
    line-height: normal;
}


.font-18 {

    // @media (min-width: $desktop)
    @include rem(font-size, 18);
    font-weight: 300;
    line-height: normal;
}

.font-20 {

    // @media (min-width: $desktop)
    @include rem(font-size, 20);
}

.font-24 {

    // @media (min-width: $desktop)
    @include rem(font-size, 24);
    line-height: calc(35/24);
}


.font-28 {
    // @media (min-width: $desktop)
    @include rem(font-size, 28);
}





small {
    font-size: .8rem;
}

.weight-300 {
    font-weight: 300;
}

.weight-500 {
    font-weight: 500;
}

.weight-600 {
    font-weight: 600;
}

.weight-700 {
    font-weight: 700;
}

.font-underline {
    text-decoration-line: underline;
}

.paragraph, .paragraph .rich-text {
    @include rem(font-size, 18);
    font-weight: 300;
    @media (min-width: $desktop) {
        line-height: calc(35/27);
        @include rem(font-size, 27);
    }
}


.paragraph-large, .paragraph-large .rich-text {
    @include rem(font-size, 18);
    font-weight: 300;
    @media (min-width: $desktop) {
        line-height: calc(35/27);
        @include rem(font-size, 27);
    }
}



.symbol-text {
    display: flex;
    margin-top: 0;
    sup {
        top: 1em;
    }
}

sub,
sup {
  font-size: .7em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  font-weight: 300;
}

sup {
    top: -.4em;
}

sub {
  bottom: -0.25em;
}