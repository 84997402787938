body.neurocrine-biosciences-funding-support-request-portal {
   
    #funding-links {
        .column-container {
            gap: 0;

            .block-link {
                margin-bottom: 24px;
            }
        }
    }
}

