.clinical-studies {
    .block-one_column {
        &:has(.accordion-toggle-all) {
            @media (min-width: $desktop) {
                display: none;
            }
        }
    }

    .clinical-study-block + .rich-text {
        padding-top: 24px;

        @media (min-width: $desktop) {
            padding-top: 0;
        }
    }   
}