#webcasts-presentations {

    .webcast-container {
        display: grid;
        grid-template-columns: 2fr 1fr;
        column-gap: 48px;

        @media (max-width: 767px) {
            grid-template-columns: 1fr;
            margin-bottom: 12px;
        }
    }

    .content-block>div {
        padding-bottom: 64px;

        &.rich-text {
            padding-bottom: 44px  !important;
        }
    }

    .webcast-block {
        .webcast-inner {
            margin-top: 24px;
        }

        h4 {
            color: $darkgreen;
            margin-top: 24px;
            margin-bottom: 20px;
            font-weight: 600;
        }
    
        p {
            font-weight: 300;
            color: $black;
        }
    
        .date {
            background-color: $green;
            font-style: italic;
            color: $white;
            font-weight: 200;
            padding: 10px 40px;
            display: inline-block;
            border-top-right-radius: 18px;
            border-bottom-right-radius: 18px;
        }
    
        a {

            display: block;
            text-decoration: underline;
            margin: 24px 0;
    
            &.bold-link {
                text-transform: uppercase;
                font-weight: 700;
            }
            
            &:hover, &:focus {
                text-decoration: none;
            }

            &+br {
                display: none;
            }
        }

        .rich-text a[href*='pdf'] {
            @extend .block-link;
            @extend .download-link;
            @extend .primary;
        }
    }

    .investors-resources {
        @media (max-width: $desktop) {
            padding-top: 0;
            padding-bottom: 64px;
        }

        .heading {
            font-size: 28px;
            margin-bottom: 24px;
        }

        ul {
            display: grid;
            row-gap: 15px;
        }
    }
}