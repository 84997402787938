#approved-medicines {
    .download-link {
        font-size: 18px;

        padding-left: 40px;

    }

    .block-hero:has(.hero_block2)+div:has(#cta) {
        @media (min-width: $desktop) {
            padding-top: 80px;
            min-height: 157px;
            width: 100%;

            .column-container {
                max-width: 100%;
                width: 100%;
            }
        }
    }
}