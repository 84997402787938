body.sitemap {
    li {
        font-weight: 500;
    }
    .rich-text a {
        &:hover, &:focus {
            text-decoration: underline;
        }
    }
    .rich-text *+ul {
        margin-top: unset;
    }

    .inner >  ul > li {
        margin-bottom: 24px;
        &:not(:has(>a)) {
            color: $darkgreen;
        }
    }
}
