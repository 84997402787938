.icon-text-block {
    padding: 48px 0;

    .items {
        display: flex;
        flex-direction: column;
    }

    li + li {
        margin-top: 48px;
    }

    .image-wrapper {
        text-align: center;
    }

    img {
        width: auto;
        display: inline-block;
        margin-bottom: 36px;
    }

    &.style_1 {
        .items {
            gap: 24px;
        }
        img {
            width: 100%;
            max-width: 300px;
        }
    }

    &.style_2 {
        .items {
            gap: 66px;
        }  
        img {
            height: 100px;
            width: auto;
        }
    }

    .item-title {
        padding: 23px 40px;
        width: 100%;
        margin-bottom: 24px;
    }

    &.style_3 {
        .items {
            gap: 48px;
        }
        img {
            height: 70px;
            margin-bottom: 12px;
        }
    }

    .rich-text {
        li {
            + li {
                margin-top: 0.5em;
            }
        }
    }

    @media (min-width: $tablet) {
        .content {
            display: flex;
            align-items: center;
        }
        img {
            margin: 0;
        }
    
        &.style_1 {
            .items {
                gap: 86px;
            }
            .image-wrapper {
                width: 25%;
            }
            .copy {
                width: 75%;
                padding-left: 50px;
            }
        }
        &.style_2 {
            .items {
                gap: 48px;
            }
            .copy {
                width: calc(100% - 275px);
                flex-grow: 1;
            }  
            .image-wrapper {
                width: 275px;
                img {
                    margin: 0 auto;
                }
            }
        }

        &.style_3 {
            .content {
                align-items: flex-start;
            }
            .image-wrapper {
                margin: 0 30px;
                flex-shrink: 0;
            }
            img {
                max-width: 100px;
                max-height: 86px;
                margin-left: auto;
                width: auto;
                height: auto;
            }
            .copy {
                flex-shrink: 1;
            }
        }
    }
}