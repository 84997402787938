#business-development {
    .rich-text {
        padding-top: 24px;
        padding-bottom: 0;
    }

    #abbvie .rich-text-container,
    #mitsubishi-tanabe-pharma .rich-text-container,
    #jnana-therapeautics .rich-text-container,
    #voyager-therapeutics .rich-text-container,
    #xenon-pharmaceuticals-inc .rich-text-container,
    #idorsia-ltd .rich-text-container,
    #takeda-pharmaceutical-company .rich-text-container,
    #sosei-heptares .rich-text-container {
        a {
            font-weight: 500;
        }

        a::after {
            display: inline-block;
            content: '';
            background: url(../../../static/img/up-arrow.svg) no-repeat;
            background-size: auto;
            height: 20px;
            width: 20px;
            margin-left: 12px;
            margin-bottom: -4px;
        }

        p:last-child {
            padding-bottom: 24px;
            border-bottom: 1px solid $gray;
        }
    }

    #sosei-heptares .rich-text-container {
        p:last-child { border-bottom: 0; }
    }
}