.cloud {
    &-container {
        position: relative;
        width: 100%;

        &>div {
            height: 750px;
        }

        &>img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        p,
        a {
            font-weight: bold;
            color: #277CA2 !important;
            text-decoration: none !important;
            margin-top: .5rem;
            font-size: .8rem;

            @media (min-width: 782px) {
                font-size: 1.125rem;
            }
        }
    }

    &-block {
        display: flex;
        flex-flow: column;
        justify-content: center;
        top: 0;
        left: 0;
        text-align: center;

        & img {
            margin: auto;
        }

        &:hover {
            cursor: pointer;

            & img {
                filter: brightness(50%) saturate(10);
            }
        }

        & .rich-text {
            line-height: 1;
        }
    }
}