.link-list-block {
	 max-width: 1330px;
	 padding: 0 20px;

	 .side-resources {
		a {
			color: $green;
			font-size: 16px;
			display: block;
			text-decoration: underline;
			font-weight: 500;
		
			&:hover {
				text-decoration: none;
			}
		}
	 }
}