body.therapeutic-areas {

    .block-hero {
        .heading-2 {
            max-width:700px;
        }
    }

    #endometriosis-resources + .block-link {
        @media (min-width: $desktop) {
            @include rem(max-width, 500);
        }
    }

    .block-one_column:has(#epilepsy-cta) {
        padding-bottom: 64px;
    }

    #epilepsy .accordion {
        padding-bottom: 32px;
    }

    .accordion-block + .accordion-block {
        margin-bottom: unset;
        .accordion {
            border-top: unset;
            padding-top: unset;
        }
    }

    .accordion-block.mb-64 {
        margin-bottom: 0 !important;

        .accordion {
            margin-bottom: 0 !important;
            padding-bottom: 64px;
        }

        h2.heading-38 {
            @include rem(font-size, 24);
            @media (min-width: $desktop) {
                @include rem(font-size, 32);
            }
        }
    }
}