.block-one_column, .block-two_column, .block-three_column, .block-four_column {

    img {
        width: 100%;
        height: auto;
    }
}

.column-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
}


.block-two_columns {
    display: grid;
    gap: 36px;
    @media (max-width: 1023px) {
        .reverse-col-mobile {
            .column-container {
                &:first-child {
                    order: 2;
                }

                &:last-child {
                    order: 1;
                }
            }
        }
    }

    @media (min-width: $desktop) {
        .grid {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 36px;
        }

        .custom-grid {
            .grid {
                $colPercentage: var(--left-col-width);
                grid-template-columns: $colPercentage 1fr !important;
            }
        }
    }
}

 
.block-three_columns {
    display: grid;
    gap: 36px;
    @media (min-width: $desktop) {
        .grid {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }
}

.block-four_columns {
    display: grid;
    gap: 36px;
    @media (min-width: $desktop) {
        .grid {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
    @media (min-width: $desktop-lg) {
        .grid {
            gap: 36px;
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}