body.about-us {

    #our-story {
        margin-bottom: 10px;
        h2 {
            padding-bottom: 10px;
        }
    }

    .webcast-image-header {
       h2 {
        @extend .heading-38;
        font-weight: 500;
       }

       .content {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .webcast-card--header2 {
        padding: 21px 39px 22px;
        border-radius: 25px;
    }

    .webcast-image-header + .icons-section-block.style-one {
        padding-top: 0;
        .items {
            background: $seafoamlight;
            border-radius: 0 0 15px 15px;
            padding: 48px 20px 48px;
        }
    }

    #wylie-vale, #lawrence-steinman, #meeting-needs {
        .font-18 {
            margin-top: 0;
        }
    }

    #wylie-vale, #lawrence-steinman {
        .rich-text {
            margin-top: -.25em;
        }
    }
}