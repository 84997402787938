.latest-filings-block {

  .icons-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  $icons: 'pdf', 'html', 'rtf', 'xbrl', 'xls', 'xsd';

  @each $icon in $icons {
    .item-#{$icon} {
      display: block;
      background: url('/static/img/docs/#{$icon}.png') center/contain no-repeat;
      width: 20px;
      height: 27px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 12px 60px 12px 0;
      text-align: left;
    }

    thead {
      border-bottom: 1px solid $darkgreen;
    }

    tr td:last-child {
      padding-right: 0;
    }

    tr {
      &:not(:last-child) {
        border-bottom: 1px solid $darkgreen;
      }
    }

    .nowrap {
      white-space: nowrap;

    }

    .max-500 {
      max-width: 500px;
    }
  }

  @media (max-width: 1023px) {
    table th, table td {
      padding: 12px 30px 12px 0;
    }
  }

  @media (max-width: 767px) {
    table {
      width: 100%;
      display: block;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;

      thead {
        display: none;
      }

      tbody {
        tr {
          display: block;
          padding-bottom: 30px;

          &:not(:first-child) {
            padding-top: 40px;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }

        td {
          display: block;
          text-align: left;
          font-size: 14px;
          position: relative;
          padding: 25px 10px 15px 0;
        }

        .form-type {
          color: $gray !important;
        }

        td::before {
          content: attr(data-label);
          position: absolute;
          left: 0;
          top: 0;
          color: $pinkdark;
          

        }
      }
    }
  }

  @media (max-width: 480px) {
    tbody td {
      font-size: 12px;
    }
  }
}