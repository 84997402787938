.accordion-block {
    .blurb {
        > .inner, .block-column_layout {
            display: grid;
            gap: 36px;
        }

        .wrapperModalLaucher {
            text-align: center;
        }
    }

    + .accordion-block {
        .accordion {
            border-top: 1px solid $darkgreen;
            padding-top: 64px;
        }
    }
}