.accordion {    
    .block-column .container {
        padding: 0;
    } 

    .blurb {
        display: none;
    }

    .accordion-trigger {
        max-width: calc($max-container - 40px);
        width: 100%;
        text-align: left;
        padding: 23px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .font-24 {
            @media (max-width: 1023px) {
                font-size: 18px;
                font-weight: 500;
            }
        }
        
        &::after {
            content: "";
            position: relative;
            display: inline-block;
            height: 13px;
            width: 23px;
            vertical-align: middle;
            margin-left: 12px;
            background: url('/static/img/caret-green.svg') center/contain no-repeat;
            transform: rotate(180deg);
            transition: .3s ease;
        }

        &.active {
            &::after {
                transform: rotate(0deg);
            }
        }

        &:not(.simple-trigger) {
            border-radius: 20px;
            background-color: $darkgreen;
            color: $white;
            padding: 23px 40px;

            &::after {
                filter: brightness(1000%) grayscale(100%) !important;
            }
        }
    }

    .section-intro.grid {
        grid-template-columns: 50% 50%;
        align-items: center;

        @media (max-width: $desktop) {
            grid-template-columns: 100%;
        }

        .accordion-toggle-all {
            margin-top: auto;
            margin-bottom: auto;

            @media (max-width: $desktop) {
                margin-top: 24px;
            }
        }

        .paragraph {
            grid-column: 1 / -1;
        }
    }
}

.accordion-toggle-all {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-bottom: 24px;
    button {
        color: $gray;
        font-size: 22px;
        line-height: calc(31/22);

        &:not(.disabled) {
            font-weight: 500;
            text-decoration: underline;
        }
    }

    @media (min-width: $tablet) {
        justify-content: flex-end;
        margin-bottom: 48px;
    }
    
}
    
// this Affects ALL Accordions. Basic Styles are shared.