.pagination {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    user-select: none;

    .page {
        margin: 0 8px;
        color: $pinkdark;
        text-decoration: none;

        &.current {
            color: $gray;
            cursor: default;
        }

        @media (min-width: 768px) {
            margin: 0 12px;
        }
    }
}