.flexible-table-block {

    img {
        max-width: 100px;
        display: block;
        margin: 0 auto;
    }

    table {
        width: 100%;
        text-indent: 0;
        border-color: inherit;
        border-collapse: collapse;
        margin: 1em 0;

        tr {
            border-bottom: none;
        }
    }

    caption {
        margin: 1em 0;
        text-align: center;
    }
 
    th, td {
        padding: 1em;
    }

    th {
        font-style: normal;
        font-weight: 600;
        @include rem(font-size, 18);
        line-height: 1.5;
    }

    td:not(:last-child) {
        border-right: 1px solid $black;
    }

    tbody tr {
        border-top: 1px solid $black;
    }

    th {
        &:not(:last-child) {
            border-right: 1px solid $black;
        }
    }
   
    .mobile-version {
        display: none;
    }

    .desktop-version {
        display: block;
        overflow-x: auto;
        width: 100%;
    }

    /* Responsive styles */
    @media screen and (max-width: 767px) {

        .desktop-version {
            display: none;
        }
        
        .mobile-version {
            display: block;
        }

        .col-section {
            + .col-section {
                margin-top: 10px;
            }
        }

        .col-content {
            padding: 20px 0;
            + .col-content {
                border-top: 1px solid $black;
            }
        }
    }
}