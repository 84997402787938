.rewards-block {
    h3 {
        color: $blue;
        font-weight: 600;
        line-height: auto;
        font-size: 14px;
        text-align: center;
    }

    a:hover {
        border-color: $pinkdark;
        color: $pinkdark;
        h3 {
            color: inherit;
        }
    }

    .item-wrapper {
        img {
            margin: 0 auto 16px;
            width: auto;
        }
    }


    
    .mobile-version {
        .grid {
            grid-gap: 24px 28px;
        }


        .canvas {
            position: relative;
            &::before {
                position: absolute;
                top: 0;
                height: 50%;
                left: -20px;
                right: -20px;
                content: '';
                display: inline-block;
                background: $darkgreen;
                z-index: -1;
            }
        }
        .center-image-wrapper {
            background-color: $white;
            border-radius: 50%;
            width: 100%;
            height: 0;
            padding-top: 100%;
            position: relative;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: calc(100% - 48px);
                height: auto;
            }
        }
    }

    @media (max-width: 767px) {

        .rich-text {
            .font-28 {
                font-size: 24px;
                line-height: calc(35/24);
            }
        }

        .intro-1 {
            background-color: $darkgreen;
            color: $white;
            margin: 0 -20px;
            padding: 48px 20px 8px;
        }

        .intro-2 {
            padding: 36px 0 60px;
        }
    }
    

    @media (min-width: $tablet) {

        .intro-2 {
            margin-top: 15px;
        }

        .canvas-wrapper {
            max-width: 901px;
            margin: 0 auto;
            margin-top: 64px;
        }
    
        .canvas {
            width: 100%;
            height: 0;
            padding-top: 98.7%;
            position: relative;
        }
    
        .center-image-wrapper  {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -48%);
            max-width: 480px;
            width: calc(100% - 420px);
        }
    
        .item-wrapper {
            position: absolute;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
    
            h3 {
                font-size: clamp(16px, 2.5vw, 22px);
                line-height: 1.1em;

            }

            img {
                transform: scale(.8);
                transform-origin: center bottom;
                width: 100% !important;
            }

            .item {
                overflow: hidden;
                
            }
        }

        .item-1, .item-6 {
            left: 50%;
            transform: translateX(-50%);
        }
    
        .item-1 {
            top: 0;
            width: percentage(235px/901px);
            img {
                max-width: 119px;
            }
        }

        .item-2 {
            top: 5%;
            right: 10%;
            width: percentage(220px/901px);
            img {
                max-width: 88px;
            }
        }
    
        .item-3 {
            top: 30%;
            right: 0;
            width: percentage(170px/901px);
            img {
                max-width: 60px;
            }
        }
    
        .item-4 {
            top: 50%;
            right: -2%;
            width: percentage(220px/901px);
            img {
                max-width: 111px;
            }
        }
    
        .item-5 {
            right: 8%;
            width: percentage(240px/901px);
            bottom: 7%;
            img {
                max-width: 118px;
            }
        }
        
        .item-6 {
            width: percentage(300px/901px);
            bottom: 0;
            img {
                max-width: 125px;
            }
        }
        .item-7 {
            left: 8%;
            bottom: 7%;
            width: percentage(220px/901px);
            img {
                max-width: 112px;
            }
        }
        .item-8 {
            top: 50%;
            left: 3%;
            width: percentage(137px/901px);
            img {
                max-width: 106px;
            }
        }
        .item-9 {
            width: percentage(137px/901px);
            top: 30%;
            left: 5%;
            img {
                max-width: 98px;
            }
        }
        .item-10 {
            width: percentage(150px/901px);
            top: 5%;
            left: 10%;
            img {
                max-width: 124px;
            }
        }
    }
}