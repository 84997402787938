.latest-news-block {
	.news-item {
		.date {
            margin-bottom: 16px;
        }
	}

    .grid {
        gap: 40px;
    }

    @media (min-width: $tablet) {
        .text-pinkdark {
            color: $gray;
        }
    }
}