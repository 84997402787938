
// This'll convert any px value to rem.
@mixin rem($property, $value) {
    #{$property}: #{calc(($value / 16))}rem;
}



$display-list: (
    'hidden': 'none',
    'block': 'block',
    'flex': 'flex'
);

@each $displayName, $value in $display-list {

    .#{$displayName} {
        display: $value;
    }

    .mobile\:#{$displayName} {
        @media(min-width: $mobile) {
            display: $value !important;
        }
    }

    .tablet\:#{$displayName} {
        @media(min-width: $tablet) {
            display: $value !important;
        }
    }

    .desktop\:#{$displayName} {
        @media(min-width: $desktop) {
            display: $value !important;
        }
    }

    .desktop-lg\:#{$displayName} {
        @media(min-width: $desktop-lg) {
            display: $value !important;
        }
    }


    .desktop-lg\:#{$displayName} {
        @media(min-width: $desktop-lg) {
            display: $value !important;
        }
    }


    // legacy tailwind classes
    .sm\:#{$displayName} {
        @media(min-width: 640px) {
            display: $value !important;
        }
    }
    
    .md\:#{$displayName} {
        @media(min-width: 782px) {
            display: $value !important;
        }
    }

    .lg\:#{$displayName} {
        @media(min-width: 960px) {
            display: $value !important;
        }
    }
    .xl\:#{$displayName} {
        @media(min-width: 1025px) {
            display: $value !important;
        }
    }

}




.background-cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
}


$zIndexes: 1,2,3;
@each $value in $zIndexes {
    .z-index-#{$value} {
        z-index: $value;
    }
}



// 
.block-padding {
    padding-top: 64px;
    padding-bottom: 64px;
}

$sizes: (
    8, 16, 24, 32, 36, 40, 46, 48, 56, 64, 72
);
@each $value in $sizes {
    .p-#{$value} {
        padding-top: #{$value}px;
        padding-bottom: #{$value}px;
        @media (max-width: 1023px) {
            padding-top: #{$value / 1.5}px;
            padding-bottom: #{$value / 1.5}px;
        }
    }
    .pt-#{$value} {
        padding-top: #{$value}px;
        @media (max-width: 1023px) {
            padding-top: #{$value / 1.5}px;
        }
    }
    .pb-#{$value} {
        padding-bottom: #{$value}px;
        @media (max-width: 1023px) {
            padding-bottom: #{$value / 1.5}px;
        }
    }
    .m-#{$value} {
        margin-top: #{$value}px;
        margin-bottom: #{$value}px;
        @media (max-width: 1023px) {
            margin-top: #{$value / 1.5}px;
            margin-bottom: #{$value / 1.5}px;
        }
    }
    .mt-#{$value} {
        margin-top: #{$value}px;

        @media (max-width: 1023px) {
            margin-top: #{$value / 1.5}px;
        }
    }
    .mb-#{$value} {
        margin-bottom: #{$value}px;
    }
}


$radii: 10, 15, 25;
@each $value in $radii {
    .border-radius-#{$value} {
        border-radius: #{$value}px;
    }
}