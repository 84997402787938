.content_block1 {
    overflow: hidden;

    .side-content.block {
        overflow: hidden;
        position: relative;
        padding: 28px 20px 13vw 20px;
        margin-bottom: 60px;

        img {
            position: absolute;
            inset: 0 0 0 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center bottom;
        }

        .side-text {
            width: 80%;
            font-size: 22px;
            margin-left: 40px;
        }
    }
    .content { 
        > h2{
            @include rem(font-size, 32);
            font-weight: 300;
            margin-bottom: 12px;
        
            @media (min-width: $desktop) {
                @include rem(font-size, 60);
                line-height: 1.2em;
                margin-bottom: 30px;
            }
        }
    }

    @media (min-width: $tablet) {
        padding: 64px 0;

        .container  {
            display: flex;
        }

        .content {
            width: 70%;
            > h2 {
                padding-right: 30px;
            }
        }

        .side-content  {
            position: absolute;
            right: 0;
            top: 0;
            width: 35%;
            height: 0;
            padding-top: 35%;

            // img {
            //     height: 100%;
            //     width: 100%;
            //     object-fit: cover;
            //     object-position: center;
            //     clip-path: ellipse(50% 86% at 51% 56%);
            // }

            .bg-image {
                position: absolute;
                inset: 0 0 0 0;
                width: 100%;
                height: 100%;
                background-size: 100% 100%;
                background-position: left top;

            }

            .side-text {
                position: absolute;
                inset: 0;
                font-size: clamp(20px, 2.01vw, 29px);
                line-height: calc(35/29);
                padding: 20px;
                text-align: right;
                p {
                    width: 70%;
                    max-width: 285px;
                    margin-left: auto;
                }
            }
        }
    }

    @media (min-width: $desktop) {
        .side-content {
            .side-text {
                padding: 64px 42px 64px;
            }
        }
    }

    @media (min-width: 1380px) {
        .content {
            width: 75%;
            max-width: 882px;
        }

        .side-content {
            width: 35%;
            max-width: 470px;
            padding: 0;
            height: calc(100% - 20px);
        }
    }
}