.latest-card {
    background: #F0F3F3;
    border-radius: 15px;
    .latest-card-inner {
        padding: 30px;
    }

    .block-link.internal-link, .block-link.download-link, .block-link.external-link {
        display: block;
        margin-top: 24px;


        text-decoration: none;
        &:hover &:focus {
            text-decoration: underline;
        }
    }

    .font-24 + .font-20 {
        margin-top: 12px;
    }


    @media (max-width: 1023px) {
        .heading-38 h3 {
            font-size: 30px !important;
        }

        .block-link {
            margin-top: 40px;
        }
    }   
}