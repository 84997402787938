.news-container {

  .filters {
    margin-top: 64px;
  }

  .results-section {
    h2 {
      margin-bottom: 66px;

      @media (min-width: $desktop) {
        margin-bottom: 24px;
      }
    }
  }

  .results-list {
    display: grid;
    gap: 24px;
    margin-top: 24px;
  }

  .pagination {
    margin-top: 50px;
    .page {
      background-color: transparent;
      border: none;
    }

    .current {
      cursor: default;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }




  .date {
    margin-bottom: 12px;
  }

  .resources-list {
    h2 {
      margin-bottom: 24px;
    }
    li {
      margin-bottom: 12px;
    }
  }

  .flex-container {
    width: 100%;

    @media (min-width: $desktop) {
      display: flex;
      justify-content: space-between;
      gap: 66px;

      .results-list {
        width: 64%;
        max-width: 750px;
        flex-shrink: 0;
      }

      .resources-list {
        width: calc(36% - 66px);
        flex-grow: 0;
      }
    }
  }
}