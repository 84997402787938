header {
    padding: 10px 20px;
    position: relative;
    z-index: 9999;
    background: #fff;

    @media (min-width: 1025) {
        padding: 30px 20px;
    }

    #primary-nav {
        &:focus {
            outline: none;
        }
    }

    .nav-container {
        max-width: 1285px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        @media (max-width: $desktop) {
            flex-flow: row wrap;
        }

        .nav-logo {
            width: 245px;
            // margin-right: 55px;

            @media (max-width: 1300px) {
                margin-right: 0;
            }

            @media (max-width: $desktop) {
                max-width: 146px;
            }
        }

        .nav-menu-items {
            flex: auto;

            @media (max-width: $desktop) {
                display: none;
                flex-basis: 100%;
                padding: 0 24px;
            }

            .desktop-nav {
                display: flex;
                justify-content: space-between;
                max-width: 961px;
                margin-left: auto;
                padding: 12px 0;
            }

            &.open {
                display: block;
                background: #fff;
                position: absolute;
                top: 63.3px;
                left: 0;
                width: 100%;
                z-index: 999;

                .px-4 {
                    padding: 0;
                }

                .sub-menu {
                    padding-bottom: 0px !important;
                }

                .top-navigation {
                    padding: 7px 0;
                }
            }
        }
        
        #nav-icon1 {
            display: none;
            img {
                height: 39px;
                width: 33px;
                object-fit: contain;
            }
            #hamburger-close {
                display: none;
            }

            @media (max-width: $desktop) {
                display: block;

                &.active {
                    #hamburger-open {
                        display: none;
                    }

                    #hamburger-close {
                        display: block;
                    }
                }
            }
        }
    }

    .primary-menu {
        @media (max-width: $desktop) {
            flex-flow: column;
        }

        &>li {
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 5px;
                bottom: -20px;
                left: 0;
                opacity: 1;
            }

            .sub-menu {
                @media (min-width: 1025px) {
                    display: none;
                    position: absolute;
                    max-width: 333px;
                    width: 100%;
                    top: 0;
                    background: #fff;
                    border-bottom-left-radius: 25px;
                    border-bottom-right-radius: 25px;
                    z-index: 9;
                    box-shadow: 4px 4px 4px 0 #0000000D, -4px 4px 4px 0 #0000000D;
                 }

                &>li {
                    padding: 0 20px 25px;
                    
                    @media (min-width: 1025px) {
                        &:first-child {
                            padding-top: 45px;
                        }
                        &:last-child {
                            padding-bottom: 245x;
                        }
                        
                    }
                    @media (max-width: $desktop) {
                        padding: 0 0 10px;
                    }
                }

                a {
                    display: block;
                    font-weight: 400;

                }
                
                @media (max-width: $desktop) {
                    display: none;
                    position: relative;
                    max-width: none;
                    padding: 1rem;
                }
            }
        }

        .top-navigation {
            @media (max-width: 1300px) {
                font-size: 16px;
            }

            @media (max-width: $desktop) {
                font-size: 12px;
            }

            @media (max-width: $desktop) {
                font-size: 14px;
                padding: 10px 0;
            }

            &.active a {
                color: $pinkdark;
                font-weight: 600;
            }

            a.active {
                color: $pinkdark;
            }
            
            &>.mainmnbtn {
                @media (max-width: $desktop) {
                    color: $darkgreen;
                    font-weight: 500;
                }
            }

            .top-menu {
                white-space: nowrap;
            }

            &.open {
                .top-menu::after {
                    transform: rotate(180deg);
                  }
                  .sub-menu{
                    display: block;
                  }
            }
            .top-menu.has-submenu {
                &::after {
                    content: url('../../../static/img/header/header-caret.svg');
                    margin-left: 8px;
                    display: inline-block;

                    @media (max-width: $desktop) {
                        content: '';
                        background: url('../../../static/img/header/header-caret.svg') no-repeat;
                        background-size: 10px 6px;
                        width: 10px;
                        height: 6px;
                    }
                }

                &:has(+.active) {
                    color: $pinkdark;
                    font-weight: 600;

                    @media (max-width: $desktop) {
                        &::after {
                            background-size: 10px 6px !important;
                            background: url('../../../static/img/header/caret-pink.svg') no-repeat;
                        }
                    }

                    @media (min-width: 1025px) {
                        &::after {
                            content: url('../../../static/img/header/caret-pink.svg');
    
                        }
                    }
                }
            }
        }
    }

    .icon-search {
        svg {
            @media (max-width: $desktop) {
                width: 26px;
                height: 25px;
            }
        }
    }

    .search-desktop {
        display: block;

        @media (max-width: $desktop) {
            display: none;
        }
    }
    
    .search-mobile {
        display: none;

        @media (max-width: $desktop) {
            display: block;
            flex: 1;
            text-align: right;
            margin-right: 42px;
        }
    }
}

//custom css
#funding-support{
    
    @media (min-width: $tablet) {
        max-width: none;
    }

    @media (min-width: 1025px) {
        max-width: 275px;
    }
}

//Topbar Banner
.topbar_list {
    display: none;
}

#home .topbar_list {
    width: 100%;
    list-style: none;
    background-image: url('../../../static/img/top_banner/tdaw-bg-desktop.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    @media (max-width: $desktop) {
        background-image: url('../../../static/img/top_banner/tdaw-bg-mobile.jpg');
    }
}

.banner-link {
    margin: 50px;

    @media (max-width: $desktop) {
        margin: 50px 5px;
    }
}

.topbar-banner {
    color: #2B5F51;
    max-width: 1600px;
    margin: auto;
    font-weight: 700;
    display: grid;
    grid-template-columns: 15% auto;
    align-items: center;
    column-gap: 30px;

    @media (max-width: $desktop) {
        grid-template-columns: auto;
        justify-content: center;
        text-align: center;
    }

    .banner-logo {
        img {
            margin: auto;
        }
    }

    .banner-header {
        font-size: 50px;
        line-height: 1.2;
        font-family: 'Grotesque-Bold', sans-serif;
        font-weight: 700;
        color: #f0c216;

        @media (max-width: $desktop) {
            font-size: 43px;
            text-align: center;
            margin: 44px auto;
        }
    }
    
    .banner-copy > p + p {
        font-family: 'Grotesque-Light', sans-serif;
        font-weight: 300;
        font-size: 30px;
        line-height: 1.2;
        color: #ffffff;

        @media (max-width: $desktop) {
            font-size: 24px;
            text-align: center;
        }

        &::after {
            content: url('../../../static/img/top_banner/banner-arrow.png');
            margin-left: 5px;
        }
    }

    .date {
        white-space: nowrap;
    }
}