body.total-rewards {
    #intro-desktop {
        display: none;
    }

    #intro-mobile {
        background-color: $darkgreen;
        color: $white;
    }

    @media (min-width: $tablet) {
        #intro-mobile, #intro-mobile-2 {
            display: none;
        } 

        #intro-desktop {
            display: block;
        }
    }
}