.investor-page {
    #introduction {
        padding-bottom: 64px;
    }

    @media(min-width: $desktop) {
        .block-hero:has(.hero_block2)+div, .block-hero:has(.hero_block2)+section {
            padding-top: 64px;
        }

        #introduction {
            padding-bottom: 64px;
        }
    }

    main {
        padding-bottom: 64px;
        @media (min-width: $tablet) {
            padding-bottom: 128px;
        }
    }

    .webcast-container {
        display: grid;
        column-gap: 48px;

        @media (max-width: $tablet) {
            grid-template-columns: 1fr;
            margin-bottom: 60px;
        }

    }
    .has-resources {
        grid-template-columns: 2fr 1fr;

        @media (max-width: $tablet) {
            grid-template-columns: 1fr;
        }
    }

    .block-resources {
        .heading {
            font-size: 28px;
        }

        ul {
            display: grid;
            row-gap: 18px;
            margin-left: 1.25rem;
        }

        ul li {
            display: block;
            padding: 0 !important;
            color: gray;
            border-radius: 15px;
            width: fit-content;
            max-width: 284px;


            // a {
            //     padding: 12px 36px;
            // }


            // This is a termporary override to match Notified
            // since Notified is NOt uptodate with ADA changes
            // this must be reverted post-launch
            &.bg-seafoam-light {
                background: transparent !important;
                border: none !important;
                font-weight: 400 !important;
            }
        }
    }

    .investors-resources {
        .heading {
            font-size: 28px;

            // TEMPORARY OVERRIDE TO MATCH NOTIFIED
            font-weight: 300 !important;
            margin-bottom: 1.5rem;
        }

        ul {
            display: grid;
            row-gap: 15px;
        }

        ul li {
            display: block;
            color: $gray;
            padding: 12px 36px;
            border-radius: 15px;
            width: fit-content;
            max-width: unset;
            border: $gray solid 1px;

            a:hover, a:focus {
                text-decoration: underline
            }
        }
    }
}


body#investors {
    @media (min-width: $desktop) {
        .block-hero:has(.hero_block2)+div .column-container, .block-hero:has(.hero_block2)+div .inner, .block-hero:has(.hero_block2)+section .column-container, .block-hero:has(.hero_block2)+section .inner {
            position: relative;
            z-index: 7;
            max-width: 100%;
            width: 100%;
        }
    }

    .block-column {
        > .container {
            padding: 0;
        }
    }

    .webcast-image-header {
        @media (max-width: 1023px) {
            .heading-38 h3, .heading-38 p {
                font-size: 30px !important;
            }
        }
    }

    #latest-webcasts-presentations {
        .grid {
            grid-auto-rows: 1fr;
            .column-container, .block-latest_presentation, .block-latest_webcast, .latest-card {
                height: 100%;
            }
        }
    }

    //////////
    // hiding for launch!
    .webcast-image-header:has(#earnings) {
        display: none;
    }
    /////////

    #earnings {
        @media (max-width: 1023px) {

            .block-link + .block-link {
                margin-top: 16px;
            }
            .grid {
                gap: 0;
            }
            .column-container:first-child {
                padding: 30px;
            }
        }

        @media (min-width: $desktop) {
            padding: 30px;
        
            .column-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 48px;
                align-items: center;  

                &:first-child {
                    padding: 30px 0;
                }
                &:last-child {
                    img {
                        height: 100%;
                        object-fit: cover;
                        object-position: right center;
                        border-radius: 15px;                    }
                }
            }
        }
    }

    .webcast-image-header:has(#earnings) {
        > .container {
            padding: 0;
        }
    }
}