body.our-responsibility {

    #esg-report {
        @media (max-width: 767px) {
            .block-link.text-center {
                text-align: left;
                margin: 0 auto;
            }
        }
    }
}