#emerging-fields {
    .image-wrapper {
        img {
            max-width: unset;
            max-height: unset;
        }
    }

    .emerging-fields-block {
        &>.container {
            padding: 0;

            @media (min-width: $mobile) {
                padding: 0 20px;
            }
        }
    }
    #neuroimmunology,
    #gene-therapy {
        .image-wrapper {
            margin: auto;
            @media (min-width: $tablet) {
                margin-right: 48px;
            }
        }
    }

    #neuroimmunology {
        .image-wrapper {
            margin-left:0;
            &>img {
                height: 120px;
                width: auto;
            }
        }
    }
    #gene-therapy {
        .image-wrapper {
            width: 110px;
            &>img {
                height: 133px;
                width: auto;
            }
        }
    }
}