.quote-block {

  &:not(.version-simple) {
    background: $darkgreen;
    color: $white;
    padding: 46px 36px;
    font-style: normal;
  }
  blockquote {
    background-color: transparent;
  }
}
