.leadership-pages {

    .intro {
        padding-bottom: 64px;
        .rich-text .inner {
            background-color: $seafoamlight;
            padding: 46px 20px; 
        }

        @media (min-width: $tablet) {
            padding-top: 64px !important;


            .rich-text .inner {
                padding: 46px 36px;
            }
        }
    }

    &:not(.leader-detail) {
        .block-hero:has(.hero_block2) {
        @media (min-width: $desktop) {
            +div,
            +section {
                .inner {
                    max-width: 100%;
                }
            }
        }
    }
    }

    &.leader-detail {
        .block-hero:has(.hero_block2) {
            @media (min-width: $desktop) {
                +div,
                +section {
                    padding-top: 180px;
                    overflow: hidden;
                    position: relative;
    
    
                    .inner {
                        all: unset;
    
                        .container {
                            position: relative;
                            z-index: 7;
                        }
    
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .leaders {
        padding: 12px 0 48px;

        img {
            display: block;
            width: 100%;
            height: auto;
            border-radius: 15px;
        }

        h2 {
            margin-bottom: 36px;
        }

        .leader-grid {
            grid-gap: 36px 32px;

            a {

                &:hover,
                &:focus,
                &.active {
                    .image {
                        filter: none;
                    }
                }
            }

            .image {
                margin-bottom: 28px;
                transition: .25s ease;
                filter: grayscale(100%);
            }
        }

        @media (min-width: $tablet) {
            padding: 64px 0;
        }
    }

    .board-of-directors {
        padding: 64px 0 48px;

        .accordion-item:not(:last-child) {
            margin-top: 0;
            border-bottom: 1px solid #2B5F51;
            padding: 23px 0;
        }
    }

    .featured-leader {
        padding-bottom: 48px;
        background-color: $seafoamlight;

        img {
            display: block;
            width: 100%;
            height: auto;
            border-radius: 15px;
            margin-bottom: 48px;
        }


        .subtitle {
            margin: 8px 0 24px;
        }

        @media (min-width: $tablet) {
            padding: 64px 0;
            background-color: unset;

            .container {
                display: flex;
                align-items: flex-start;
                gap: 48px;
            }

            img {
                width: 367px;
                height: auto;
                flex-shrink: 0;
            }

            .copy {
                padding-top: 10px;
                flex-grow: 0;
            }
        }
    }
}