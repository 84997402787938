.text-thumbnails-block {
    background-color: rgba(219, 237, 237, 0.15);
    padding: 64px 0;

    .images {
        align-items: center;
        padding: 0 26px;
        max-width: 500px;
        margin: 24px auto 0;
    }
    
    .image,
    .image img {
        width: 100%;
    }


    @media (min-width: $tablet) {
        background-color: transparent;
        padding: 0;

        .inner {
            background-color: rgba(219, 237, 237, 0.15);
            padding: 42px;
            border-radius: 15px;
            display: grid;
            grid-template-columns: 2.5fr 1fr;
            grid-column-gap: 50px ;
        }

        .images {
            margin-top: 0;
            padding: 0;
        }
    }

    @media (min-width: $desktop) {
        .inner {
            padding: 42px 70px;
        }
    }
}