.cookie-banner {
    position: fixed;
    width: 100%;
    background: $seafoam-base;
    padding: calc(15px + 24px + 40px) 20px 24px;
    z-index: 9999;
    bottom: 0;

    .cookie-banner-wrap {
        max-width: 1440px;
        margin: 0 auto;
    }

    .cookie-content-wrap {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        max-width: 1160px;
        margin: 0 auto;
    }

    .close {
        display: block;
        position: absolute;
        right: 20px;
        top: 20px;
        
        span {
            font-size: .55556rem;
            color: $darkgreen;
        }
    }

    p {
        flex: 1 100%;
        color: #000;
        font-weight: 300;
        margin: 0 0 34px;
    }

    a {
        color: $pinkdark;
        text-decoration: underline;
    }

    a,
    .accept {
        width: fit-content;
        font-weight: 500;
        margin-top: auto;
        margin-bottom: auto;
    }

    .accept {
        margin: 0 0 0 auto;
        color: #fff;
        padding: 10px 40px;
        background: $pinkdark;
        border-radius: 15px;
    }

    @media (min-width: $desktop) {
        padding: 85px 35px 61px;

        .close {
            top: 28px;
            right: 35px;
        }

        p,
        .accept {
            flex: 1 auto;
            margin: auto 0;
        }

        a {
            margin: auto 80px auto auto;
        }

        .accept {
            flex: 0;
        }

        .cookie-content-wrap {
            flex-wrap: nowrap;
        }
    }
}