body.pipeline {

    .block-hero .heading-2 {
        @media (min-width: $tablet) {
            max-width: 760px;
        }
    }

    #pipeline-footnote {
        p {
            @extend .symbol-text;
            &:not(:first-child) {
                sup {
                    font-size: .55em;
                }
            }
            .text-pinkdark sup {
                top: -0.9em;
                right: 0.1em;
            }
        }
    }

    .block-indication_link {
        display: inline-block;
    }

    .pipeline-table {

        a {
            font-weight: 300;
            &::after {
                display: none !important;
            }
        }
        table {
           width: 100%;
        }
    
        thead {
            background-color: $darkgreen;
            
            color: $white;
            th:first-child {
                border-radius: 15px 0 0 15px;
            }
            th:last-child {
                border-radius: 0 15px 15px 0;
            }
        }
    
        tbody {
            @extend .font-18;
        }
    
        td {
            border-color: $mint;
            border-width: 1px;
            border-left: none;
            padding: 12px 5px 12px 20px;
            height: 80px;
        }
    
        tr {
            td:last-child {
                border-right: none;
            }
        }
    
        th {
            border-color: white;
            text-align: left;
            border-width: 1px;
            padding: 12px 5px 12px 20px;
        }
    
        a {
            color: $pinkdark;
            text-decoration: underline;
        }
    
        #research {
            min-width: 137px !important;
            background: linear-gradient(90deg, #2B5F51 0.27%, #099590 399.39%);
        }
    
        #one, #two, #three {
            min-width: 107px !important;
            width: 107px !important;
            white-space: nowrap;
        }
    
        #one {
            background: linear-gradient(90deg, #2B5F51 -99.13%, #099590 298.79%);
        }
        #two {
            background: linear-gradient(90deg, #2B5F51 -199.73%, #099590 199.39%);
        }
        #three {
            background: linear-gradient(90deg, #2B5F51 -300.64%, #099590 100%);
        }
    
        $research-col: 137px; // leaving, in case Research phase needs to be added back in in future
        $phase-col: 124.8px;
        $phase-gutter: 20px;
    
        .bar {
            height: 23px;
            background: linear-gradient(90deg, #2B5F51 0%, #0BBAB4 100%);
    
            &.research {
                width: calc(($research-col - $phase-gutter/2));
            }
            &.phase1 {
                width: calc(percentage(1/6) - $phase-gutter/2);
                &.initiating {
                    width: calc(percentage(1/6) - $phase-gutter/2 - $phase-gutter/1.3);
                }
                &.completed {
                    width: calc(percentage(1/3) - $phase-gutter);
                }
            }
            &.phase2 {
                width: calc(percentage(1/2));
                &.completed {
                    width: calc(percentage(2/3));
                }
            }
            &.phase3 {
                width: calc(percentage(5/6));
                &.completed {
                    width: 100%;
                }
            }
        }
    }
    
    
    .pipeline-accordion {
    
        a {
            color: $pinkdark;
            text-decoration: underline;
        }
    
        .status {
            display: flex;
            margin-bottom: 12px;
        }
    
        // TODO:  do this with dynamic javascript math
        $research-size: 66.7px;
        $phase-gutter: 24px;
        $phase-one: 51.9px;
        $phase-two: 54.9px;
        $phase-three: 55.25px;
    
    
        .bar {
            height: 23px;
            background: linear-gradient(90deg, #2B5F51 0%, #0BBAB4 100%);
    
            &.research {
                width: calc(($research-size)/2);
            }
            &.phase1 {
                width: calc($phase-one/2);

                &.initiating {
                    width: calc($phase-one/2 - 10px);
                }
            }
            &.phase2 {
                width: calc($phase-one + $phase-gutter + $phase-two/2);
                &.completed {
                    width: calc($phase-one + $phase-gutter + $phase-two);
                }
            }
            &.phase3 {
                width: calc(($phase-one + $phase-gutter*2 + $phase-two + $phase-three/2));
            }
        }
    
        .blurb {
            padding-top: 24px;
            .inner  {
                display: grid;
                grid-gap: 24px;
        
                @extend .font-16;
                font-weight: 400;
                
                h4, h5 {
                    color: $darkgreen;
                    font-weight: 600;
                }
                
                h5 {
                    font-size: 14px;
                    margin-right: 24px;
                }
            }
        }
    
        ul {
            display: grid;
            grid-gap: 35px;
            li {
                margin-top: 0;
            }
        }
    }
    
    
    .pipeline-filters {
        margin: 24px 0 0;
    
        .custom-select {
            margin-bottom: 24px;
        }
        @media (min-width: $desktop) {
            margin: 48px 0;
            display: flex;
            justify-content: space-between;
    
            .custom-select {
                width: 30.3%;
            }
        }
    }

    .accordion-toggle-all {
        margin-bottom: 24px;
    }

    .accordion {    
        .accordion-trigger {
            &::after {
                margin: 0;
                position: absolute;
                right: 40px;
            }
            h3 {
                width: calc(100% - 30px);  
            }
        }

        .inner + .inner {
            margin-top: 24px;
        }
    }
}