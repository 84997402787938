.product-block {
    padding: 40px 0;
    background: $seafoamlight;


    .logos {
        border-bottom: 1px solid $gray;
        margin-bottom: 24px;
        text-align: center;
    }

    img {
        margin: 0 auto 24px;

        @media (min-width: $tablet) {
            margin: 0 0 24px;
        }
    }

    .information {
        display: grid;
        grid-gap: 24px;
    }

    @media (min-width: $tablet) {
        padding: 0;
        background: none;;

        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: $seafoamlight;
            padding: 40px;
        }

        .logos {
            margin: 0;
            border-bottom: 0;
            width: 26.7%;
            border-right: 1px solid $gray;
            padding-right: 40px;
            margin-right: 40px;
            flex-shrink: 0;
        }

        .information {
            width: 74.3%;
            grid-gap: 12px;
            &:last-child {
                margin-top: 12px;
            }
        }
    }
    
    .download-link {
        text-decoration: underline;

        &:hover, &:focus {
            text-decoration: none !important;
        }
    }
}