.breadcrumb-wrapper {
    border-top: 1px solid $lightgray3;
}

.breadcrumb {
    min-height: 56px;
    padding: 16px 20px;
    display: flex;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;

    @media (max-width: $tablet) {
        padding: 16px 20px;
    }

    li {

        &:not(:last-child) a {
            text-decoration: underline;
        }

        &:last-child {
            color: $pinkdark;
        }
    }
}

.breadcrumb-desktop {
    display: block;

    @media (max-width: $tablet) {
        display: none;
    }
}

.breadcrumb-mobile{
    display: none;

    @media (max-width: $tablet) {
        display: block;
    }
}