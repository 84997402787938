.icons-section-block {
    padding: 10px 0 48px;

    .items {
        display: flex;
        gap: 58px;
        justify-content: center;
    }

    img {
        height: 115px;
        width: auto;
        margin: 0 auto 10px;
    }

    &.style-two {

        .items {
            @extend .cols-3;
        }
        .item {
            background-color: $seafoamlight;
            border-radius: 15px;
            padding:48px 18px;

            img {
                margin: 0 auto 24px;
            }

            .rich-text {
                h1, h2, h3 {
                    @include rem(font-size, 64);
                    line-height: 1em;
                }
            }
        }
    }


    @media (min-width: $tablet) {
        $cols-2-gutter: 50px;
        .items {
            gap: 64px $cols-2-gutter;
        }

        .item {
            width: calc(50% - #{$cols-2-gutter / 2});
            max-width: 500px;
        }

        img {
            margin: 0 auto 10px;
        }
    }


    @media (min-width: $desktop) {
        $cols-3-gutter: 48px;

        .items.cols-3 {
            $cols-3-gutter-total: #{48px * 2};
            gap: $cols-3-gutter;

            .item {
                width: calc(33.333% - #{$cols-3-gutter-total / 3});
            }
        }
    }
}