body.contact-us {
    padding-bottom: 48px;
    .block-padding, main>.rich-text {
        padding-top: 0;
    }

    .block-hero + div, .block-hero + section {
        padding-top: 100px;
    }

    #address {
        @media (min-width: $desktop) {
            p {
                display: inline-flex;
                &:not(:last-child) {
                    position: relative;
                    &::after {
                        position: relative;
                        font-weight: 200;
                        content: '|';
                        padding: 0 clamp(20px, 2vw, 40px);
                        display: inline;
                    }
        
                }
            }
        }
    }
}