
.hero_block1 {
  padding: 60px 0;

  .serendipity-text {

    @media (min-width: $desktop) {
      margin-bottom: -8rem;
      display: inline-block;
      margin-left: -1rem;
    }
  }
}

.block-hero {
  img {
    @extend .background-cover;
    @extend .z-index-1;
  }
}


.hero_block2 {
  padding: 64px 0;
  @media (min-width: $desktop) {
    padding: 100px 0 257px;
    overflow: hidden;
    
    img {
      height: 100%;
      z-index: -1;
      object-position: right top;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      height: 157px;
      right: 0;
      width: 100%;
      z-index: 1;
      background: rgba(#D9D9D9, .5);
    }
  }
}

.block-hero:has(.hero_block2) {
  + div, + section {
    padding-top: 60px;
  }
  @media (min-width: $desktop) {
    + div, + section {
      padding-top: 100px;
      margin-top: -157px;
      overflow: hidden;
      position: relative;
      .inner, .column-container {
        position: relative;
        z-index: 7;
        max-width: 955px;
        width: 92%;

        @media (min-width: $desktop-lg) {
          width: 100%;
        }
      }

      .col-wide {
        max-width: none;
      }

      .container {
        position: relative;
        z-index: 7;
      }
  
      &::before {      
        content: "";
        position: absolute;
        top: 2px;
        right: 0;
        width: 190vw;
        height: 157px;
        z-index: 1;
        transform: skew(47deg);
        transform-origin: right center;
        background: $white;
        mask: radial-gradient(155px 157px ellipse at 100% 0, transparent 99.9%, white 100%);
      }
    }
  }
}
