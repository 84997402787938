$mint: #DBEDED;
$darkgreen:   #2B5F51;
$green: #2B5F51;
$teal: #0BBAB4;
$blue: #007DA5;
$gray: #6B6B6B;
$pinkdark: #993366;
$pink: #CF3B91;

$dred: red;

$border: $gray;

$red: #C00202;

$seafoam-base: #DBEDED;
$seafoam: rgba($seafoam-base, .4);
$seafoamlight: rgba($seafoam-base, .15);

$black: black;
$offwhite: #F8F8F8;
$white: white;

$lightgray: #d3d3d3;
$lightgray2: rgba(#e7e7e7, .5);
$lightgray3: #cecece;
$lightgray4: #f5f5f5;

$teal2: #068e89;

$colors-list: (
    'pink': $pink,
    'pinkdark' : $pinkdark,
    'mint': $mint,
    'green': $green,
    'teal': $teal,
    'teal2': $teal2,
    'gray': $gray,
    'darkgreen': $darkgreen,
    'seafoam': $seafoam,
    'seafoam-light': $seafoamlight,
    'red': $red,
    'dred': $dred,
    'black': $black,
    'white': $white,
    'offwhite': $offwhite,
    'lightgray2': $lightgray2,
    'lightgray3': $lightgray3,
    'lightgray4': $lightgray4,
);

@each $key, $val in $colors-list {
    .text-#{$key} {
        color: $val;
    }

    .bg-#{$key} {
        background-color: $val;
    }
}